import { to } from 'await-to-js';
import { getError } from './errorsService';
import { amplifyFetchUserSession, getStorageInstance } from './amplifyService.js';
import { getProductLocationByUser } from './locationService';
import { bootstrapJWT } from './bootstrapJWTResource';
import { decodeIdTokenAndGetEmail, setAccessTokenExpiry } from './authUtils.js';
import { fetchAndSetRecordDatadogReplayCookie } from './recordDatadogReplayService.js';

export async function bootstrapAuthToken(app) {
  let auth;

  const [, userData] = await to(amplifyFetchUserSession());

  if (userData?.tokens?.accessToken?.payload) {
    const { client_id: clientId, username, exp, device_key: deviceKey } = userData.tokens.accessToken.payload;

    const key = `CognitoIdentityServiceProvider.${clientId}.${username}.refreshToken`;

    auth = {
      access_token: userData.tokens.accessToken?.toString(),
      id_token: userData.tokens.idToken?.toString(),
      refresh_token: await getStorageInstance().getItem(key),
      expiration: exp,
      expiresIn: exp - parseInt(Date.now() / 1000),
      deviceKey,
    };
  }

  if (auth == null) {
    console.log('erro: objeto auth inválido');
    console.log(userData);

    const error = { error: JSON.stringify({ ...getError('MissingTokenData') }) };
    return [error, null];
  }

  const [idTokenErr, email] = decodeIdTokenAndGetEmail(auth.id_token);
  if (idTokenErr) return [idTokenErr, null];

  const expiresAt = 1000 * auth.expiration;
  setAccessTokenExpiry(expiresAt, auth.expiresIn);

  const jwt = {
    token: auth.access_token,
    refreshToken: auth.refresh_token,
    deviceKey: auth.deviceKey,
  };

  const [[jwtErr]] = await Promise.all([to(bootstrapJWT(jwt)), fetchAndSetRecordDatadogReplayCookie(email)]);

  if (jwtErr) {
    const error = { message: getError(jwtErr.code, null), step: 'bootstrapJWT' };
    console.log(jwtErr);
    return [error, null];
  }

  const location = await getProductLocationByUser({ app, email });

  return { location, jwt: true };
}
