import Cookies from 'js-cookie';
import { Buffer } from 'buffer';
import * as recordSessionResource from '@/resources/recordSessionResource';

function setCookie() {
  Cookies.set('x-ca-rs', true, {
    domain: process.env.VUE_APP_COOKIE_DOMAIN,
    secure: window.location.protocol === 'https:',
    path: '/',
    expires: 30,
  });
}

function clearCookie() {
  Cookies.remove('x-ca-rs', {
    domain: process.env.VUE_APP_COOKIE_DOMAIN,
    path: '/',
  });
}

export async function fetchAndSetRecordDatadogReplayCookie(email) {
  const hash = Buffer.from(email).toString('base64');
  try {
    const { data: recordDatadogReplay } = await recordSessionResource.recordDatadogReplay(hash);
    if (recordDatadogReplay) setCookie();
    else clearCookie();
  } catch (ex) {
    console.error('Error on set Datadog Cookie');
    return Promise.resolve();
  }
}
